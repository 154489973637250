import React from 'react'
import history from './utils/history'
import track from './utils/track'
import Loadable from "react-loadable"
import { Switch, Route, Router } from "react-router-dom"
// import { injectVConsole }  from "./utils/vconsole"
import './utils/interceptors'
import './App.css'


// if (/h5-fat/.test(window.location.href)) {
//    const { injectVConsole } = require("./utils/vconsole")
//    injectVConsole()
// }


window.onerror = function (message, file, line, column, errorObject) {
  let data = {message: message, file, line, column: (column || "###"), errorObject: (!!errorObject ? JSON.stringify(errorObject.stack) : "###")}
  track('error', data)
}

const Loading = ({ isLoading, error }) => {
    if (isLoading) {
        return <div>Loading...</div>
    }
    else if (error) {
        return <div>Sorry, there was a problem loading the page.</div>
    } else {
        return null
    }
}

const routers = [
{
    "path": "/guide",
    "component": Loadable({
      loader: () => import("./modules/guide/index"),
      loading: Loading
    })
},
{
    "path": "/faq/:vip",
    "component": Loadable({
      loader: () => import("./modules/faq/index"),
      loading: Loading
    })
},
{
    "path": "/customer/:step",
    "component": Loadable({
      loader: () => import("./modules/customer/index"),
      loading: Loading
    })
},
{
    "path": "/feedback/:step",
    "component": Loadable({
      loader: () => import("./modules/feedback/index"),
      loading: Loading
    })
},
{
    "path": "/setting",
    "component": Loadable({
      loader: () => import("./modules/setting/index"),
      loading: Loading
    })
},
{
  "path": "/promo",
  "component": Loadable({
    loader: () => import("./modules/promotion/index"),
    loading: Loading
  })
},
{
    "path": "/user/:type",
    "component": Loadable({
      loader: () => import("./modules/invite/index"),
      loading: Loading
    })
},{
  "path": "/adv/:channel",
  "component": Loadable({
    loader: () => import("./modules/adv/index"),
    loading: Loading
  })
}].map((item, inx) => (<Route key={inx} exact={true} path={item.path} component={item.component} />))

function App() {
  return (
    <div className="App">
      <Router history={history}>
          <Switch>
              {routers}
          </Switch>
      </Router>
    </div>
  )
}

export default App
